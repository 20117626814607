// Estilizando os componentes com MUI e Styled Components
import {styled} from '@mui/system';
import {Button, Card, CardActions, Typography} from "@mui/material";
import CardContent from "@mui/material/CardContent";

export const StyledCard = styled(Card)(({theme}) => ({
    position: 'relative',
    borderRadius: 10,
    border: '1px solid black',
    overflow: 'hidden', // Garante que o conteúdo não saia dos limites do Card
}));

export const StyledCardTitle = styled(Typography)(({theme}) => ({
    fontWeight: 'bold',
    fontSize: '1.25rem',
    color: 'black'
}));

export const StyledCardDescription = styled(Typography)(({theme}) => ({
    marginTop: 1,
    fontSize: '1rem',
}));

export const StyledCardContent = styled(CardContent)(({theme}) => ({
    padding: 0,
    height: 500,
    marginTop: '8px',
}));

export const StyledButton = styled(Button)(({theme, disabled}) => ({
    width: 'auto',
    padding: '1 3',
    fontWeight: 'bold',
    backgroundColor: disabled ? theme.palette.grey[500] : theme.palette.primary.main,
    color: 'white',
    '&:hover': {
        backgroundColor: disabled ? theme.palette.grey[500] : theme.palette.primary.dark,
    },
}));

export const StyledCardFooter = styled(CardActions)(({theme}) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexFlow: 'row',
    flexDirection: 'column',
    marginTop: '10px',
    gap: '12px',
}));