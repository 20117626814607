import styled from "styled-components";
import CardBox from "../../../lib/components/CardBox";
import {Box, Button, Card, CardMedia} from "@mui/material";
import Link from "@mui/material/Link";

// Styled Components using MUI's styled
export const Container = styled(Box)(({theme}) => ({
    maxWidth: 1200,
    margin: '0 auto',
    padding: 4,
}));

export const NavButtons = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 4,
}));

export const ContentWrapper = styled(Box)(({theme}) => ({
    overflowY: 'auto', // Enable vertical scrolling only on content area
    maxHeight: 'calc(100vh - 150px)', // Adjust the height to fit the viewport, subtracting some margin for the buttons and padding
    paddingRight: 2, // Add right padding to avoid clipping of content
}));

export const ContentGrid = styled(Box)(({theme}) => ({
    display: 'grid',
    gap: 30,
    gridTemplateColumns: '1fr 1fr 1fr', // One card per row
}));

export const StyledCard = styled(Card)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    border: '0.5px solid black'
}));

export const StyledLink = styled("a")({
    padding: 1,
    color: '#1565c0',
    margin: 0,
    fontSize: '24px',
})

export const StyledButton = styled(Button)({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px'
});

export const BaseCardBox = styled(CardBox)`
  margin: 0;

  h3 {
    color: #66717f;
    margin: 0;
    font-size: 24px;
  }
`;