import styled from "styled-components";
import CardBox from "../../../lib/components/CardBox";
import Dialog from "@mui/material/Dialog";
import {TextField} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export const BaseCardBox = styled(CardBox)`
  margin: 50px 0;

  h3 {
    color: #66717f;
    margin: 0;
    font-size: 24px;
  }

  .searchButtonBox {
    width: 100%;
    display: flex;
    justify-content: end;
  }
`;

export const BaseDialog = styled(Dialog)`
  form {
    margin: 30px 25px;
  }

  .MuiDialogActions-root {
    padding: 0;
    margin-top: 25px;
  }

  h2 {
    padding: 0;
    margin-bottom: 15px;
  }

  input {
    width: 400px;
  }
`;

export const BaseTextFieldSearch = styled(TextField)`
  width: 100%;
  margin-bottom: 25px !important;
`;

export const EditButton = styled(EditIcon)`
  color: #999;
  margin-right: 15px;

  :hover {
    cursor: pointer;
    color: #555;
  }
`;