import React, {useEffect, useState} from 'react';
import {Box, Button, Container, FormControl, InputLabel, MenuItem, Select, TextField, Typography} from '@mui/material';
import {BaseCardBox, BaseDialog, BaseTextFieldSearch, EditButton} from "./styles";
import {CreateUniversity, UniversityEntity, UpdateUniversity} from "../../../lib/api/university.protocol";
import {RequestApi} from "../../../lib/api/requests";
import {ISnackBarSettings} from "../../../lib/helpers/snackbar-interface";
import CustomizedSnackbars from "../../../lib/components/SnackBar";
import {useNavigate} from "react-router-dom";
import {UniversityApi} from "../../../lib/api/university";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {Header} from "../../admin-files/pages/list/style";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteButton from "../../../lib/components/DeleteButton";
import dayjs from "dayjs";
import CustomizedTable from "../components/Table";

export interface ITableBodyCreateUniversity {
    name: string;
    typeOfContent: string;
    dateOfPublish: string;
    dateOfUpdate: string;
    actions: JSX.Element;
}

export const CreateUniversityMS = () => {
    const [universityContent, setUniversityContent] = useState<UniversityEntity[]>([]);

    const [contentName, setContentName] = useState<string>('');
    const [contentType, setContentType] = useState<string>('');
    const [contentSummary, setContentSummary] = useState<string>('');
    const [contentLink, setContentLink] = useState<string>('');
    const [contentId, setContentId] = useState<string>('');

    // Função para calcular a data de ontem
    const getYesterdayDate = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate()); // Subtrai um dia
        return yesterday.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    };

    const [publishDate, setPublishDate] = useState<string>(getYesterdayDate);

    // URL do preview da imagem
    const [selectedImagePreviewUrl, setSelectedImagePreviewUrl] = useState<string>('');

    // arquivo da imagem para subir
    const [selectedImageBlob, setSelectedImageBlob] = useState<any>();

    // URL da imagem que subiu no cluster do azure
    const [selectedImageFileUrl, setSelectedImageFileUrl] = useState<string>('');

    const [snackBarSettings, setSnackBarSettings] = useState<ISnackBarSettings>({
        show: false,
    });

    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [tableBody, setTableBody] = useState<ITableBodyCreateUniversity[]>([]);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState<number | undefined>(undefined);
    const tableHead = ['Nome', 'Tipo Conteúdo', 'Data de Publicação', 'Data de Atualização', 'Ações'];

    const navigate = useNavigate();

    // Handle Save operation
    const handleSave = async () => {
        // Validações
        const validations = [
            {condition: !contentName, message: 'Preencha o nome do conteúdo!'},
            {condition: !contentType, message: 'Preencha o tipo do conteúdo!'},
            {condition: !contentLink, message: 'Preencha o link do conteúdo!'},
            {condition: !publishDate, message: 'Preencha a data de publicação do conteúdo!'},
            {condition: !contentSummary, message: 'Preencha o resumo do conteúdo!'},
            {condition: contentSummary.length > 65, message: 'Limite máximo de 65 caracteres para o resumo!'},
            {
                condition: selectedImageBlob && selectedImageBlob.length === 0,
                message: 'Insira uma imagem para o conteúdo!'
            },
        ];

        for (const validation of validations) {
            console.log(`Validação: ${validation.condition}`)
            if (validation.condition) {
                setSnackBarSettings({
                    show: true,
                    message: validation.message,
                    severity: 'warning',
                });
                return;
            } else {
                //resetSnackBar();
            }
        }

        // Processamento adicional
        await handleFileImage(selectedImageBlob);
    };

    const handleFileImage = async (e: any): Promise<void> => {
        if ((e && e.target && e.target.files) || (e && e.target && e.target.files && e.target.files.length !== 0)) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            let count;
            const formData = new FormData();

            for (count = e.target.files.length; count > 0; count--) {
                formData.append('files', e.target.files[count - 1]);
            }

            try {
                const response = await RequestApi.retrieveFilesLinks(formData);
                setSelectedImageFileUrl(response[0].fileUrl);

                if (!isEdit) {
                    // Corpo da requisição
                    const body: CreateUniversity = {
                        name: contentName,
                        type_of_content: contentType,
                        resume: contentSummary,
                        link: contentLink,
                        date_of_publish: publishDate,
                        fileUrlImage: response[0].fileUrl,
                    };

                    try {
                        const universityContent = await UniversityApi.createUniversity(body);

                        setContentName('');
                        setContentType('');
                        setContentSummary('');
                        setContentLink('');
                        setPublishDate(getYesterdayDate);
                        setSelectedImagePreviewUrl('');
                        setSelectedImageBlob(null);
                        setSelectedImageFileUrl('');

                        setSnackBarSettings({
                            show: true,
                            message: 'Sucesso ao criar conteúdo da universidade MS',
                            severity: 'success',
                        });
                        setOpen(false);
                        getUniversityAllContent();
                    } catch (err: any) {
                        setSnackBarSettings({
                            show: true,
                            message: err.message,
                            severity: 'error',
                        });
                        resetSnackBar();
                    }
                    return;
                }

                // Corpo da requisição
                const body: UpdateUniversity = {
                    name: contentName,
                    type_of_content: contentType,
                    resume: contentSummary,
                    link: contentLink,
                    fileUrlImage: response[0].fileUrl,
                };

                try {
                    await UniversityApi.editUniversity(contentId, body);

                    setContentName('');
                    setContentType('');
                    setContentSummary('');
                    setContentLink('');
                    setPublishDate(getYesterdayDate);
                    setSelectedImagePreviewUrl('');
                    setSelectedImageBlob(null);
                    setSelectedImageFileUrl('');
                    setContentId('');

                    setSnackBarSettings({
                        show: true,
                        message: 'Sucesso ao editar conteúdo da universidade MS',
                        severity: 'success',
                    });
                    setOpen(false);
                    getUniversityAllContent();
                } catch (err: any) {
                    setSnackBarSettings({
                        show: true,
                        message: err.message,
                        severity: 'error',
                    });
                    resetSnackBar();
                }
            } catch (err: any) {

                if (err.code === 'ERR_NETWORK') {
                    return setSnackBarSettings({
                        show: true,
                        message: 'Servidor instável, aguarde alguns segundos e recarregue a página por favor',
                        severity: 'error',
                    });
                }

                if (err.message === 'Network Error') {
                    setSnackBarSettings({
                        show: true,
                        message: 'A imagem deve ter no máximo 3mb',
                        severity: 'error',
                    });
                } else {
                    setSnackBarSettings({
                        show: true,
                        message: err.message,
                        severity: 'error',
                    });
                }
                resetSnackBar();
            }
        }

        if (isEdit) {
            let count;
            const formData = new FormData();
            let response = undefined;

            if (e && e.target && e.target.files && e.target.files.length > 0) {
                for (count = e.target.files.length; count > 0; count--) {
                    formData.append('files', e.target.files[count - 1]);
                }

                response = await RequestApi.retrieveFilesLinks(formData);
                setSelectedImageFileUrl(response[0].fileUrl);
            }

            try {
                // Corpo da requisição
                const body: UpdateUniversity = {
                    name: contentName,
                    type_of_content: contentType,
                    resume: contentSummary,
                    link: contentLink,
                    fileUrlImage: response ? response[0].fileUrl : selectedImageFileUrl,
                };

                try {
                    await UniversityApi.editUniversity(contentId, body);

                    setContentName('');
                    setContentType('');
                    setContentSummary('');
                    setContentLink('');
                    setPublishDate(getYesterdayDate);
                    setSelectedImagePreviewUrl('');
                    setSelectedImageBlob(null);
                    setSelectedImageFileUrl('');
                    setContentId('');

                    setSnackBarSettings({
                        show: true,
                        message: 'Sucesso ao editar conteúdo da universidade MS',
                        severity: 'success',
                    });
                    setOpen(false);
                    getUniversityAllContent();
                } catch (err: any) {
                    setSnackBarSettings({
                        show: true,
                        message: err.message,
                        severity: 'error',
                    });
                    resetSnackBar();
                }
            } catch (err: any) {

                if (err.code === 'ERR_NETWORK') {
                    return setSnackBarSettings({
                        show: true,
                        message: 'Servidor instável, aguarde alguns segundos e recarregue a página por favor',
                        severity: 'error',
                    });
                }

                if (err.message === 'Network Error') {
                    setSnackBarSettings({
                        show: true,
                        message: 'A imagem deve ter no máximo 3mb',
                        severity: 'error',
                    });
                } else {
                    setSnackBarSettings({
                        show: true,
                        message: err.message,
                        severity: 'error',
                    });
                }
                resetSnackBar();
            }
        }
    };

    const resetSnackBar = () => {
        setTimeout(() => {
            setSnackBarSettings({show: false});
        }, 3000);
    };

    // Handle image file change
    const handleImageChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImagePreviewUrl(URL.createObjectURL(file));
            setSelectedImageBlob(event);
        }
    };

    const getTypeOfContentText = (item: string) => {
        switch (item) {
            case 'material_de_apoio':
                return 'Material de Apoio';
            case 'artigos_cientificos':
                return 'Artigos Cientifícos';
            case 'comunicados':
                return 'Comunicados';
            case 'treinamentos_e_certificacoes':
                return 'Treinamentos e Certificações';
            default:
                return '-'
        }
    }

    const handleEdit = (data: UniversityEntity) => {
        setIsEdit(true);
        setOpen(true);

        setContentName(data.name);
        setContentType(data.type_of_content);
        setContentSummary(data.resume);
        setContentLink(data.link);
        setSelectedImageFileUrl(data.fileUrlImage);
        setSelectedImagePreviewUrl(data.fileUrlImage);
        setSelectedImageBlob(data.fileUrlImage);
        setContentId(data.id);
    }

    const mountTableBody = () => {
        universityContent.map((item: UniversityEntity) => {
            setTableBody((prev) =>
                [
                    ...prev,
                    {
                        name: item.name,
                        typeOfContent: getTypeOfContentText(item.type_of_content),
                        dateOfPublish: dayjs(item.date_of_publish).format('DD/MM/YYYY') + ' ' + dayjs(item.createdAt).format('HH:mm:ss'),
                        dateOfUpdate: dayjs(item.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
                        actions: (
                            <div>
                                <EditButton onClick={() => handleEdit(item)}/>

                                <DeleteButton id={item.id} callback={handleDelete}/>
                            </div>
                        ),
                    },
                ]
            );
        });
    };

    const handleDelete = async (id: string) => {
        try {
            UniversityApi.deleteUniversityContent(id)
                .then(response => {
                    setSnackBarSettings({
                        show: true,
                        message: 'Sucesso ao excluir conteúdo',
                        severity: 'success',
                    });
                    resetSnackBar();
                    getUniversityAllContent()
                })
                .catch((err: any) => {
                    setSnackBarSettings({
                        show: true,
                        message: err.message,
                        severity: 'error',
                    });
                    resetSnackBar();
                    setUniversityContent([]);
                })
        } catch (err: any) {
            setSnackBarSettings({
                show: true,
                message: err.message,
                severity: 'error',
            });
            resetSnackBar();
            setUniversityContent([]);
        }
    }

    const getUniversityAllContent = async () => {
        try {
            const university: UniversityEntity[] = await UniversityApi.getAllUniversityContent();

            if (university.length > 0) {
                // Ordena o array pelo campo `createdAt`
                const sortedData: UniversityEntity[] = university
                    .sort((a: UniversityEntity, b: UniversityEntity) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
                setUniversityContent(sortedData);
            }

            if (university.length === 0) {
                setUniversityContent([]);
            }
        } catch (err: any) {
            setSnackBarSettings({
                show: true,
                message: err.message,
                severity: 'error',
            });
            resetSnackBar();
            setUniversityContent([]);
        }
    };

    useEffect(() => {
        setTableBody([]);
        mountTableBody();
    }, [universityContent])

    useEffect(() => {
        getUniversityAllContent();
    }, []);

    return (
        <Container>
            <BaseCardBox>
                <Header>
                    <Typography variant="h3" component="h3" gutterBottom>
                        Universitário MS
                    </Typography>
                    <div>
                        <Button
                            onClick={() => {
                                setIsEdit(false);
                                setOpen(true);
                            }}
                            variant="contained"
                            startIcon={<AddBoxIcon/>}
                        >
                            Novo Cadastro
                        </Button>
                    </div>
                </Header>

                <CustomizedTable tableHead={tableHead} tableBody={tableBody}/>

                <BaseDialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <form>
                        {!isEdit ? (
                            <DialogTitle id="alert-dialog-title">{'NOVO CONTEÚDO UNIVERSITÁRIO'}</DialogTitle>
                        ) : (
                            <DialogTitle id="alert-dialog-title">{'EDITAR CONTEÚDO UNIVERSITÁRIO'}</DialogTitle>
                        )}
                        <TextField
                            label="Nome do Conteúdo"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={contentName}
                            required
                            onChange={(e) => setContentName(e.target.value)}
                        />

                        <FormControl fullWidth margin="normal">
                            <InputLabel>Tipo de Conteúdo</InputLabel>
                            <Select
                                value={contentType}
                                required
                                onChange={(e) => setContentType(e.target.value)}
                                label="Tipo de Conteúdo"
                            >
                                <MenuItem value="material_de_apoio">Materiais de Apoio</MenuItem>
                                <MenuItem value="artigos_cientificos">Artigos Científicos</MenuItem>
                                <MenuItem value="treinamentos_e_certificacoes">Treinamentos e Certificações</MenuItem>
                                <MenuItem value="comunicados">Comunicados</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            label="Resumo do Conteúdo"
                            variant="outlined"
                            fullWidth
                            required
                            margin="normal"
                            inputProps={{maxLength: 65}}
                            helperText={`${contentSummary.length}/65`}
                            value={contentSummary}
                            onChange={(e) => setContentSummary(e.target.value)}
                        />

                        <TextField
                            label="Link do Conteúdo"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            required
                            value={contentLink}
                            helperText='Cole o link do vídeo compartilhado que está no Google Drive ou no Youtube'
                            onChange={(e) => setContentLink(e.target.value)}
                        />

                        {
                            !isEdit && (
                                <TextField
                                    label="Data da Publicação"
                                    type="date"
                                    variant="outlined"
                                    fullWidth={false}
                                    required
                                    margin="normal"
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{
                                        min: getYesterdayDate(), // Restringe para não aceitar datas anteriores a ontem
                                    }}
                                    value={publishDate}
                                    onChange={(e) => setPublishDate(e.target.value)}
                                />
                            )
                        }

                        <Box sx={{marginTop: '1rem'}}>
                            <Typography variant="subtitle1">Imagem do Conteúdo</Typography>
                            <input
                                accept="image/*"
                                style={{display: 'none'}}
                                id="upload-image"
                                required
                                type="file"
                                onChange={handleImageChange}
                            />
                            <label htmlFor="upload-image">
                                <Button variant="outlined" component="span" sx={{marginTop: '0.5rem'}}>
                                    Upload Imagem
                                </Button>
                            </label>
                            {selectedImagePreviewUrl && (
                                <Box mt={2}>
                                    <Typography variant="subtitle2">Prévia:</Typography>
                                    <img src={selectedImagePreviewUrl} alt="Selected"
                                         style={{width: '100%', maxHeight: '400px', objectFit: 'fill'}}/>
                                </Box>
                            )}
                        </Box>

                        <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: '2rem'}}>
                            <Button variant="outlined" color="secondary" onClick={() => setOpen(false)}>
                                Cancelar
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleSave}>
                                {isEdit ? 'Editar' : 'Salvar'}
                            </Button>
                        </Box>
                    </form>
                </BaseDialog>
            </BaseCardBox>

            {snackBarSettings.show && (
                <CustomizedSnackbars
                    message={snackBarSettings.message}
                    severity={snackBarSettings.severity}
                />
            )}
        </Container>
    );
}
