import {
    CreateUniversity,
    GetCertificate,
    UniversityAnalystCertificate,
    UniversityEntity,
    UniversityFinishTraining, UpdateUniversity
} from "./university.protocol";
import axiosInstance from "./api";

export class UniversityApi {
    static async getAllUniversityContent(): Promise<UniversityEntity[]> {
        return await axiosInstance
            .get(`/university`)
            .then((response) => response.data);
    }

    static async deleteUniversityContent(id: string): Promise<any> {
        return await axiosInstance
            .delete(`/university/${id}`)
            .then((response) => response.data);
    }

    static async createUniversity(data: CreateUniversity): Promise<any> {
        return await axiosInstance
            .post(`/university`, data)
            .then((response) => response.data);
    }

    static async editUniversity(id: string, data: UpdateUniversity): Promise<any> {
        return await axiosInstance
            .put(`/university/${id}`, data)
            .then((response) => response.data);
    }

    static async getByTypeOfContent(typeOfContent: string): Promise<UniversityEntity[]> {
        return await axiosInstance
            .get(`/university/analyst/${typeOfContent}`)
            .then((response) => response.data);
    }

    static async getAllTrainings(analystId: string): Promise<UniversityEntity[]> {
        return await axiosInstance
            .get(`/university/analyst/trainings/${analystId}`)
            .then((response) => response.data);
    }

    static async fininshTraining(data: UniversityFinishTraining): Promise<UniversityEntity[]> {
        return await axiosInstance
            .post(`/university/analyst/finish-training`, data)
            .then((response) => response.data);
    }

    static async getCertificate(data: UniversityAnalystCertificate): Promise<GetCertificate> {
        return await axiosInstance
            .get(`/university/analyst/certificate/${data.id_analyst}&${data.id_training}`)
            .then((response) => response.data);
    }

}