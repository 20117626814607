import { Route, Routes } from 'react-router-dom';
import { CompaniesPage } from 'modules/companies/pages/list';
import { JobTitlesPage } from 'modules/job-titles/pages/list';
import { InstrumentsPage } from 'modules/instruments/pages/list';
import { SectorsPage } from 'modules/sectors/pages/list';
import { UsersPage } from 'modules/users/pages/list';
import { Presentation } from 'modules/auth/pages/presentation';
import { AdmFilesPage } from 'modules/admin-files/pages/list';
import { RouteGuard } from './route-guard';
import { useUser } from 'modules/auth/context/auth.consumer';
import { RoleType, userRole } from 'modules/auth/common/role';
import { User } from 'modules/auth/context/auth.provider';
import { AssesmentPageRoot } from 'modules/assessment-management/pages/assesment-page-root';
import { ConsultantRequestPageRoot } from 'modules/requests/pages/consultant-requests/consultant-requests-page-root';
import { MyRequestsPageRoot } from 'modules/requests/pages/my-requests/my-requests-page-root';
import {UniversityMS} from "../modules/university/pages";
import {CreateUniversityMS} from "../modules/create-university/pages";

export const Router = () => {
  const user = useUser();
  const role = userRole(user as User);
  return (
    <Routes>
      <Route path={'/'} element={<Presentation />} />
      <Route
        path={'/empresas'}
        element={
          <RouteGuard role={role} conditions={[RoleType.ADMIN]}>
            <CompaniesPage />
          </RouteGuard>
        }
      />
      <Route
        path={'/solicitacoes'}
        element={
          <RouteGuard
            role={role}
            conditions={[RoleType.ADMIN, RoleType.CONSULTOR, RoleType.ANALISTA]}
          >
            {typeof user?.role === 'string' && user?.role === 'ANALISTA' ? (
              <MyRequestsPageRoot />
            ) : (
              <ConsultantRequestPageRoot />
            )}
          </RouteGuard>
        }
      />
      <Route
        path={'/instrumentos'}
        element={
          <RouteGuard role={role} conditions={[RoleType.ADMIN]}>
            <InstrumentsPage />
          </RouteGuard>
        }
      />
      <Route
        path={'/cargos'}
        element={
          <RouteGuard role={role} conditions={[RoleType.ADMIN]}>
            <JobTitlesPage />
          </RouteGuard>
        }
      />
      <Route
        path={'/setores'}
        element={
          <RouteGuard role={role} conditions={[RoleType.ADMIN]}>
            <SectorsPage />
          </RouteGuard>
        }
      />
      <Route
        path={'/usuarios'}
        element={
          <RouteGuard role={role} conditions={[RoleType.ADMIN]}>
            <UsersPage />
          </RouteGuard>
        }
      />
      <Route
        path={'/arquivos'}
        element={
          <RouteGuard
            role={role}
            conditions={[RoleType.ADMIN, RoleType.CONSULTOR]}
          >
            <AdmFilesPage />
          </RouteGuard>
        }
      />
      <Route
        path={'/provas'}
        element={
          <RouteGuard
            role={role}
            conditions={[RoleType.ADMIN, RoleType.CONSULTOR]}
          >
            <AssesmentPageRoot />
          </RouteGuard>
        }
      />
      <Route
          path={'/cadastro/universidade-ms'}
          element={
              <RouteGuard
                  role={role}
                  conditions={[RoleType.ADMIN]}
              >
                  <CreateUniversityMS />
              </RouteGuard>
          }
      />
      <Route
          path={'/universidade-ms'}
          element={
              <RouteGuard
                  role={role}
                  conditions={[RoleType.ANALISTA]}
              >
                  <UniversityMS />
              </RouteGuard>
          }
      />
    </Routes>
  );
};
