import React, {useEffect, useState} from 'react';
import {Typography, CardActions, CardMedia} from '@mui/material';
import Divider from "@mui/material/Divider";
import {StyledLink} from "../../pages/styles";
import dayjs from "dayjs";
import {UniversityEntity, UniversityFinishTraining} from "../../../../lib/api/university.protocol";
import {
    StyledButton,
    StyledCard,
    StyledCardContent,
    StyledCardDescription,
    StyledCardFooter,
    StyledCardTitle
} from "./styles";
import {UniversityApi} from "../../../../lib/api/university";
import CustomizedSnackbars from "../../../../lib/components/SnackBar";
import {ISnackBarSettings} from "../../../../lib/helpers/snackbar-interface";
import {useAuth} from "../../../auth/context/auth.consumer";
import styled from "styled-components";

// Função para obter o src do iframe com base na URL do vídeo (YouTube ou Google Drive)
const getIframeSrc = (url: string) => {
    // Se a URL for do YouTube, extraímos o ID do vídeo e geramos o URL de embed
    if (url.includes('youtube.com')) {
        const videoId = new URL(url).searchParams.get('v');
        return `https://www.youtube.com/embed/${videoId}`;
    }

    // Se a URL for do Google Drive, extraímos o fileId e geramos o URL de preview
    else if (url.includes('drive.google.com')) {
        const fileId = url.split('/d/')[1].split('/')[0];
        return `https://drive.google.com/file/d/${fileId}/preview`;
    }

    return '';
};

const StyledCardMedia = styled(CardMedia)({
    height: 200,
    objectFit: 'fill',
});

const TrainingCard = ({ trainingsContent, reloadTrainings }: any) => {
    const { user } = useAuth();

    const [snackBarSettings, setSnackBarSettings] = useState<ISnackBarSettings>({
        show: false,
    });

    const trainings: UniversityEntity[] = trainingsContent && trainingsContent.sort((a: UniversityEntity, b: UniversityEntity) => {
        // Ordena por cursos não finalizados primeiro
        if (a.is_finished === b.is_finished) {
            // Se ambos têm o mesmo status, verifica se ambos têm createdAt e faz a ordenação
            const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
            const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
            return dateA - dateB;
        }
        return a.is_finished ? 1 : -1;
    });

    const getTotalCursos = () => {
        const total: number = trainings && trainings.filter((training: any) => !training.is_finished).length;
        return `${total} ${total === 1 ? 'curso disponível' : 'cursos disponíveis'}`
    }

    const getTotalCursosConcluidos = () => {
        const total: number = trainings && trainings.filter((training: any) => training.is_finished).length;
        return `${total} ${total === 1 ? 'curso concluído' : 'cursos concluídos'}`
    }

    const handleFinishCourse = async (item: UniversityEntity) => {
        try {
            if (user) {
                const body: UniversityFinishTraining = {
                    id_training: item.id,
                    id_analyst: user.id,
                    type_of_content: 'treinamentos_e_certificacoes',
                }
                UniversityApi.fininshTraining(body)
                    .then(() => {
                        reloadTrainings('treinamentos_e_certificacoes');
                        setSnackBarSettings({
                            show: true,
                            message: `Treinamento: ${item.name} finalizado com sucesso!`,
                            severity: 'success',
                        });
                        resetSnackBar();
                    })
                    .catch((err: any) => {
                        setSnackBarSettings({
                            show: true,
                            message: err.message,
                            severity: 'error',
                        });
                        resetSnackBar();
                    });
            }

        } catch (err: any) {
            setSnackBarSettings({
                show: true,
                message: err.message,
                severity: 'error',
            });
            resetSnackBar();
        }
    }

    const resetSnackBar = () => {
        setTimeout(() => {
            setSnackBarSettings({show: false});
        }, 3000);
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginBottom: '8px', flexFlow: 'column'}}>
                <span>{getTotalCursos()}</span>
                <span>{getTotalCursosConcluidos()}</span>
            </div>
            {
                trainings && trainings.map((training: UniversityEntity, index: number) => (
                    <div style={{marginTop: '1rem', marginBottom: '1rem'}}>
                        <StyledCard key={index} style={{border: training.is_finished ? 'solid 2px green' : 'solid 1px red'}}>
                            <div style={{backgroundColor: training.is_finished ? 'green' : 'red', color: 'white', padding: '2px 0px 2px 16px'}}>
                                {training.is_finished ? 'Concluído (confira o certificado na aba: MEUS CERTIFICADOS)' : 'A fazer'}
                            </div>
                            <div style={{padding: '8px'}}>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px'}}>
                                    <StyledCardTitle>
                                        {training.name}
                                    </StyledCardTitle>

                                    <StyledCardFooter>
                                        <StyledButton
                                            onClick={() => handleFinishCourse(training)}
                                            disabled={training.is_finished}
                                        >
                                            {training.is_finished ? 'Curso Concluído' : 'Finalizar Curso'}
                                        </StyledButton>

                                        <Typography variant="body1">Publicado em: {dayjs(training.date_of_publish).format('DD/MM/YYYY')}</Typography>
                                    </StyledCardFooter>

                                </div>
                                <StyledCardDescription>{training.resume}</StyledCardDescription>
                            </div>

                            <StyledCardContent>
                                {
                                    getIframeSrc(training.link) != '' ? (
                                        <iframe
                                            style={{display: 'flex', justifyContent: 'center'}}
                                            title={training.name}
                                            width="100%"
                                            height="100%"
                                            src={getIframeSrc(training.link)}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    ) : (
                                        <StyledCardMedia
                                            sx={{height: '100vh'}}
                                            image={training.fileUrlImage}
                                            title={training.name}
                                        />
                                    )
                                }

                            </StyledCardContent>
                        </StyledCard>

                        <Divider style={{marginTop: '1rem'}}/>
                    </div>
                ))
            }

            {snackBarSettings.show && (
                <CustomizedSnackbars
                    message={snackBarSettings.message}
                    severity={snackBarSettings.severity}
                />
            )}
        </>
    );
};

export default TrainingCard;
