import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {User} from "../../../modules/auth/context/auth.provider";
import {RoleType, userRole} from "../../../modules/auth/common/role";
import {useAuth, useUser} from "../../../modules/auth/context/auth.consumer";
import {Page, SystemMenu} from "../AppContainer";
import {Outlet, useNavigate} from "react-router-dom";
import {Footer} from "../Footer";
import {MenuItem} from "@mui/material";
import {
    Apartment,
    AssignmentInd,
    Build,
    Business,
    FilePresent,
    Logout,
    Person,
    QuestionAnswer,
    School,
    Work
} from "@mui/icons-material";
import Container from "@mui/material/Container";
import {LogoSideBar, ToolbarSideBar} from "./style";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

const drawerWidth = 240;

interface AppContainerV2 {
    children: React.ReactNode;
    pages: Page[];
    menus: SystemMenu[];
}

export const AppContainerV2: React.FC<AppContainerV2> = ({children, pages, menus,}) => {
    const user: User | null = useUser();
    const {logout} = useAuth();

    const role: RoleType = userRole(user as User);
    const navigate = useNavigate();

    const availablePages: Page[] = pages.filter((page) => page.roles.includes(role));

    const availableMenus: SystemMenu[] = menus.filter((menu: SystemMenu) =>
        menu.roles.includes(role),
    );

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLoggout = () => {
        logout()
            .then(() => navigate('login'))
    }

    return (
        <React.Fragment>
            <Box sx={{display: 'flex'}}>
                <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
                    <ToolbarSideBar disableGutters>
                        <LogoSideBar onClick={() => navigate('/')}/>

                        <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                            <Box sx={{display: 'flex', flexFlow: 'column', alignItems: 'flex-start'}}>
                                <Typography sx={{color: 'white'}}>Nível de
                                    acesso: <strong>{user?.role.toUpperCase()}</strong></Typography>
                                <Typography sx={{color: 'white'}}>Usuário: <strong>{user?.name}</strong></Typography>
                            </Box>
                            <Tooltip title="Perfil">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ml: 2}}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Avatar
                                        alt={`${user?.name}`}
                                        src={`${user?.profilePicture}`}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>

                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            slotProps={{
                                paper: {
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&::before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                },
                            }}
                            transformOrigin={{horizontal: 'right', vertical: 'top'}}
                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        >
                            <MenuItem onClick={handleLoggout}>
                                <ListItemIcon>
                                    <Logout fontSize="small"/>
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </ToolbarSideBar>
                </AppBar>
                <Drawer variant="permanent" sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
                }}>
                    <Toolbar key={'toolbar-principal'} disableGutters/>
                    <Box sx={{overflow: 'auto'}}>
                        {
                            availableMenus.map((menu: SystemMenu, index: number) => {
                                switch (menu.label) {
                                    case 'GERENCIAMENTO E CADASTROS':
                                        return (
                                            <List key={index * 10}>
                                                <Typography
                                                    key={'list-title-gerenciamento'}
                                                    sx={{
                                                        marginTop: '2rem',
                                                        marginLeft: '0.5rem',
                                                        fontWeight: '600',
                                                    }}>GERENCIAMENTO E CADASTROS</Typography>

                                                {
                                                    availablePages.map((page: Page, indexPage: number) => (
                                                        <ListItem key={indexPage} disablePadding>
                                                            <ListItemButton>
                                                                <ListItemIcon>
                                                                    {
                                                                        page.label === 'Setores' ?
                                                                            <Apartment/> :
                                                                            page.label === 'Instrumentos' ?
                                                                                <Build/> :
                                                                                page.label === 'Cargos' ?
                                                                                    <Work/> :
                                                                                    page.label === 'Empresas' ?
                                                                                        <Business/> :
                                                                                        page.label === 'Usuários' ?
                                                                                            <Person/> :
                                                                                            page.label === 'Arquivos' ?
                                                                                                <FilePresent/> :
                                                                                                page.label === 'Universidade MS' ?
                                                                                                    <School/> :
                                                                                                    <School/>
                                                                    }
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    onClick={() => navigate(page.route)}
                                                                    primary={page.label}/>
                                                            </ListItemButton>
                                                        </ListItem>
                                                    ))
                                                }
                                            </List>
                                        )

                                    case 'AVALIAÇÕES':
                                        return (
                                            <>
                                                <Divider/>
                                                <List>
                                                    <ListItem key={'avaliacoes-menu'} disablePadding>
                                                        <ListItemButton>
                                                            <ListItemIcon>
                                                                {<AssignmentInd/>}
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                onClick={() => navigate('/solicitacoes')}
                                                                primary={menu.label}/>
                                                        </ListItemButton>
                                                    </ListItem>
                                                </List>
                                            </>
                                        )

                                    case 'ARQUIVOS':
                                        return (
                                            <>
                                                <Divider/>

                                                <List>
                                                    <ListItem key={'arquivos-menu'} disablePadding>
                                                        <ListItemButton>
                                                            <ListItemIcon>
                                                                {<FilePresent/>}
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                onClick={() => navigate('/arquivos')}
                                                                primary={menu.label}/>
                                                        </ListItemButton>
                                                    </ListItem>
                                                </List>
                                            </>
                                        )

                                    case 'PROVAS':
                                        return (
                                            <>
                                                <Divider/>

                                                <List>
                                                    <ListItem key={'provas-menu'} disablePadding>
                                                        <ListItemButton>
                                                            <ListItemIcon>
                                                                {<QuestionAnswer/>}
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                onClick={() => navigate('/provas')}
                                                                primary={menu.label}/>
                                                        </ListItemButton>
                                                    </ListItem>
                                                </List>
                                            </>
                                        )

                                    case 'UNIVERSIDADE MS':
                                        return (
                                            <>
                                                <Divider/>

                                                <List>
                                                    <ListItem key={'universidade-ms-analista-menu'} disablePadding>
                                                        <ListItemButton>
                                                            <ListItemIcon>
                                                                {<School/>}
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                onClick={() => navigate('/universidade-ms')}
                                                                primary={menu.label}/>
                                                        </ListItemButton>
                                                    </ListItem>
                                                </List>
                                            </>
                                        )
                                }
                            })
                        }
                    </Box>
                </Drawer>
                <Box component="main" sx={{flexGrow: 1, p: '3px 3x 0 0'}}>
                    <Toolbar key={'toolbar-main-principal'} disableGutters/>
                    <Container sx={{my: 2}}>
                        {children}
                    </Container>
                    <Outlet/>
                    <Footer/>
                </Box>
            </Box>
        </React.Fragment>
    );
}
