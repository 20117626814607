import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {AuthProvider} from 'modules/auth/context/auth.provider';
import {Router} from 'router/router';
import {initializeApp} from 'firebase/app';
import {Page, SystemMenu} from 'lib/components/AppContainer';
import {AuthRouter} from 'modules/auth/auth.router';
import {RoleType} from 'modules/auth/common/role';
import {firebaseConfig} from 'services/firebaseConfig';
import {ThemeProvider} from '@mui/material/styles';
import CurrentTheme from './themes';
import {CssBaseline} from '@mui/material';
import {SnackbarProvider} from 'notistack';
import AssessmentExecutionRoutes from 'modules/assessment-execution/router';
import {AppContainerV2} from "./lib/components/AppContainerV2";

initializeApp(firebaseConfig);

const pages: Page[] = [
  {
    label: 'Setores',
    route: '/setores',
    roles: [RoleType.ADMIN],
  },
  {
    label: 'Instrumentos',
    route: '/instrumentos',
    roles: [RoleType.ADMIN],
  },
  {
    label: 'Cargos',
    route: '/cargos',
    roles: [RoleType.ADMIN],
  },
  {
    label: 'Empresas',
    route: '/empresas',
    roles: [RoleType.ADMIN],
  },
  {
    label: 'Usuários',
    route: '/usuarios',
    roles: [RoleType.ADMIN],
  },
  {
    label: 'Arquivos',
    route: '/arquivos',
    roles: [RoleType.ADMIN],
  },
  {
    label: 'Universidade MS',
    route: '/cadastro/universidade-ms',
    roles: [RoleType.ADMIN],
  }
];

const menus: SystemMenu[] = [
  {
    label: 'GERENCIAMENTO E CADASTROS',
    roles: [RoleType.ADMIN],
  },
  {
    label: 'AVALIAÇÕES',
    roles: [RoleType.ADMIN, RoleType.CONSULTOR, RoleType.ANALISTA],
  },
  {
    label: 'ARQUIVOS',
    roles: [RoleType.CONSULTOR],
  },
  {
    label: 'PROVAS',
    roles: [RoleType.CONSULTOR, RoleType.ADMIN],
  },
  {
    label: 'UNIVERSIDADE MS',
    roles: [RoleType.ANALISTA],
  }
];

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider theme={CurrentTheme}>
        <CssBaseline />
        <BrowserRouter>
          <AuthProvider>
            <AuthRouter>
              <Routes>
                <Route
                  path="/assessment-execution/*"
                  element={<AssessmentExecutionRoutes />}
                />
                <Route
                  path="*"
                  element={
                    <>
                      <AppContainerV2 pages={pages} menus={menus}>
                        <Router />
                      </AppContainerV2>
                      {/*<AppContainer pages={pages} menus={menus}>*/}
                      {/*  <Router />*/}
                      {/*</AppContainer>*/}
                    </>
                  }
                />
              </Routes>
            </AuthRouter>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
