import styled from "styled-components";
import Toolbar from "@mui/material/Toolbar";
import logo from "../../assets/images/logo-ms.png";

export const ToolbarSideBar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  flex-flow: row;
  height: 70px;
  padding: 5px;
`

export const LogoSideBar = styled.div`
  width: 150px;
  height: 70px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: auto;
  cursor: pointer;
`;