'use client';

import React, {useEffect, useState} from 'react';
import TrainingCard from "../components/Treinamentos";
import {UniversityApi} from "../../../lib/api/university";
import {GetCertificate, UniversityAnalystCertificate, UniversityEntity} from "../../../lib/api/university.protocol";
import CustomizedSnackbars from "../../../lib/components/SnackBar";
import {
    BaseCardBox,
    Container,
    ContentGrid,
    ContentWrapper,
    NavButtons,
    StyledButton,
    StyledCard,
    StyledLink
} from "./styles";
import {Button, CardActions, CardContent, CardMedia, Typography} from "@mui/material";
import {ISnackBarSettings} from "../../../lib/helpers/snackbar-interface";
import styled from "styled-components";
import dayjs from "dayjs";
import {useAuth} from "../../auth/context/auth.consumer";
import DownloadIcon from "@mui/icons-material/Download";

const StyledCardMedia = styled(CardMedia)({
    height: 200,
    objectFit: 'fill',
});


export const UniversityMS = () => {
    const {user} = useAuth();

    const [activeSection, setActiveSection] = useState('material_de_apoio');
    const [snackBarSettings, setSnackBarSettings] = useState<ISnackBarSettings>({
        show: false,
    });

    const [universityContent, setUniversityContent] = useState<UniversityEntity[]>([]);

    const handleButtonClick = (section: string) => {
        setUniversityContent([]);
        setActiveSection(section);
    };

    const getTotalMateriais = () => {
        const total = universityContent.length;

        switch (activeSection) {
            case 'material_de_apoio':
            case 'artigos_cientificos':
            case 'comunicados':
                return `${total} ${total === 1 ? 'material disponível' : 'materiais disponíveis'}`;
            case 'certificados':
                return `${total} ${total === 1 ? 'certificado disponível' : 'certificados disponíveis'}`;
        }
    }

    const getUniversityContent = async () => {
        try {
            switch (activeSection) {
                case 'material_de_apoio':
                case 'artigos_cientificos':
                case 'comunicados':
                    const university: UniversityEntity[] = await UniversityApi.getByTypeOfContent(activeSection);

                    if (university.length > 0) {
                        setUniversityContent(university);
                    }

                    if (university.length === 0) {
                        setUniversityContent([]);
                    }

                    break;
                case 'treinamentos_e_certificacoes':
                    console.log('buscar treinamentos');
                    break;
                case 'certificados':
                    console.log('buscar certificados')
                    break;
            }
        } catch (err: any) {
            setSnackBarSettings({
                show: true,
                message: err.message,
                severity: 'error',
            });
            resetSnackBar();
            setUniversityContent([]);
        }
    };

    const getUniversityTrainingContent = async () => {
        try {
            if (user) {
                const trainings: UniversityEntity[] = await UniversityApi.getAllTrainings(user.id);

                if (trainings.length > 0) {
                    setUniversityContent(trainings);
                }

                if (trainings.length === 0) {
                    setUniversityContent([]);
                }
            }

        } catch (err: any) {
            setSnackBarSettings({
                show: true,
                message: err.message,
                severity: 'error',
            });
            resetSnackBar();
            setUniversityContent([]);
        }
    }

    const getUniversityAnalystCertificate = async () => {
        try {
            if (user) {
                const trainings: UniversityEntity[] = await UniversityApi.getAllTrainings(user.id);

                if (trainings.length > 0) {
                    const getTrainingsFinished = trainings.filter((training: UniversityEntity) => training.is_finished)
                    setUniversityContent(getTrainingsFinished);
                }

                if (trainings.length === 0) {
                    setUniversityContent([]);
                }
            }

        } catch (err: any) {
            setSnackBarSettings({
                show: true,
                message: err.message,
                severity: 'error',
            });
            resetSnackBar();
            setUniversityContent([]);
        }
    }

    const resetSnackBar = () => {
        setTimeout(() => {
            setSnackBarSettings({show: false});
        }, 3000);
    };

    useEffect(() => {
        switch (activeSection) {
            case 'material_de_apoio':
            case 'artigos_cientificos':
            case 'comunicados':
                getUniversityContent();
                break;
            case 'treinamentos_e_certificacoes':
                getUniversityTrainingContent();
                break;
            case 'certificados':
                getUniversityAnalystCertificate();
                break;
        }

    }, [activeSection]);

    const getMessageByTypeOfContent = () => {
        switch (activeSection) {
            case 'material_de_apoio':
                return 'Nenhum material de apoio disponível, volte em breve para conferir!';
            case 'artigos_cientificos':
                return 'Nenhum artigo cientifíco disponível, volte em breve para conferir!';
            case 'comunicados':
                return 'Nenhum comunicado disponível, volte em breve para conferir!';
            case 'certificados':
                return 'Seus certificados serão exibidos aqui.';
            default:
                return 'Nenhum conteúdo disponível no momento!';
        }
    }

    const downloadCertificate = (id_training: string, training_name: string) => {
        try {
            if (user) {
                const payload: UniversityAnalystCertificate = {
                    id_training,
                    id_analyst: user.id,
                }

                UniversityApi.getCertificate(payload)
                    .then((response: GetCertificate) => {
                        // String base64 representando o PDF
                        const base64String: string = response.data;
                        const fileName = `certificado_${training_name}.pdf`;

                        // Converter base64 para Blob
                        const base64ToBlob = (base64: string, mimeType: string): Blob => {
                            const byteCharacters = atob(base64); // Decodifica a string base64
                            const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
                            const byteArray = new Uint8Array(byteNumbers);
                            return new Blob([byteArray], {type: mimeType});
                        };

                        // Tipo MIME para PDF
                        const mimeType = "application/pdf";

                        // Criar o Blob a partir da string base64
                        const blob = base64ToBlob(base64String, mimeType);

                        // Criar um link para download
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = fileName;

                        // Simular clique no link para iniciar o download
                        link.click();

                        // Liberar a URL criada para evitar vazamentos de memória
                        URL.revokeObjectURL(link.href);
                    })
                    .catch((error) => {
                        console.error("Erro ao baixar o certificado:", error);
                    });
            }

        } catch (err: any) {
            setSnackBarSettings({
                show: true,
                message: err.message,
                severity: 'error',
            });
            resetSnackBar();
            setUniversityContent([]);
        }
    }

    return (
        <Container>
            <Typography variant="h2" component="div" style={{marginBottom: '1rem'}}>
                Universidade MS
            </Typography>

            <BaseCardBox>
                <NavButtons>
                    <Button variant={activeSection === 'material_de_apoio' ? 'contained' : 'outlined'}
                            onClick={() => handleButtonClick('material_de_apoio')}>
                        Materiais de Apoio
                    </Button>
                    <Button variant={activeSection === 'artigos_cientificos' ? 'contained' : 'outlined'}
                            onClick={() => handleButtonClick('artigos_cientificos')}>
                        Artigos Científicos
                    </Button>
                    <Button variant={activeSection === 'comunicados' ? 'contained' : 'outlined'}
                            onClick={() => handleButtonClick('comunicados')}>
                        Comunicados
                    </Button>
                    <Button variant={activeSection === 'treinamentos_e_certificacoes' ? 'contained' : 'outlined'}
                            onClick={() => handleButtonClick('treinamentos_e_certificacoes')}>
                        Treinamentos e Certificações
                    </Button>
                    <Button variant={activeSection === 'certificados' ? 'contained' : 'outlined'}
                            onClick={() => handleButtonClick('certificados')}>
                        Meus Certificados
                    </Button>
                </NavButtons>

                {/* Wrapper that allows scrolling for the content area */}

                {(activeSection === 'material_de_apoio' || activeSection === 'artigos_cientificos' || activeSection === 'comunicados') && (
                    <React.Fragment>
                        {
                            universityContent.length > 0 ? (
                                <React.Fragment>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'flex-end',
                                            marginBottom: '8px',
                                            marginTop: '8px',
                                            flexFlow: 'column'
                                        }}>
                                        <span>{getTotalMateriais()}</span>
                                    </div>

                                    <ContentWrapper>
                                        <ContentGrid>
                                            {universityContent.map((item: UniversityEntity) => (
                                                <StyledCard key={item.id}>
                                                    <StyledCardMedia
                                                        sx={{height: 140}}
                                                        image={item.fileUrlImage}
                                                        defaultValue='lib/assets/images/logo-ms.png' // Chama quando a imagem não carrega
                                                        title={item.name}
                                                    />
                                                    <CardContent>
                                                        <Typography
                                                            variant="h6"
                                                            component="div"
                                                            style={{
                                                                wordWrap: "break-word",
                                                                overflow: "hidden",
                                                                whiteSpace: "normal"
                                                            }}>
                                                            {item.name}
                                                        </Typography>
                                                        <Typography variant="body2"
                                                                    color="text.secondary"
                                                                    sx={{mt: 1}}
                                                                    style={{
                                                                        wordWrap: "break-word",
                                                                        overflow: "hidden",
                                                                        whiteSpace: "normal"
                                                                    }}>
                                                            {item.resume}
                                                        </Typography>
                                                        <Typography variant="body1" sx={{mt: 3}}>Publicado
                                                            em: {dayjs(item.date_of_publish).format('DD/MM/YYYY')}</Typography>
                                                    </CardContent>

                                                    <CardActions>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'flex-end'
                                                            }}>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'flex-start',
                                                                    alignItems: 'flex-start',
                                                                    marginBottom: '4px',
                                                                    marginTop: '8px',
                                                                    flexFlow: 'column',
                                                                    gap: '12px'
                                                                }}>
                                                                <StyledLink rel="noopener noreferrer" href={item.link}
                                                                            target="_blank">
                                                                    <StyledButton variant="contained"
                                                                                  color="primary">Visitar</StyledButton>
                                                                </StyledLink>
                                                            </div>
                                                        </div>
                                                    </CardActions>
                                                </StyledCard>
                                            ))}
                                        </ContentGrid>
                                    </ContentWrapper>
                                </React.Fragment>
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        marginBottom: '8px',
                                        marginTop: '8px',
                                        flexFlow: 'column'
                                    }}>
                                    <Typography variant="body1">{getMessageByTypeOfContent()}</Typography>
                                </div>
                            )
                        }

                    </React.Fragment>
                )}

                {activeSection === 'treinamentos_e_certificacoes' && (
                    <TrainingCard trainingsContent={universityContent} reloadTrainings={getUniversityTrainingContent}/>
                )}

                {activeSection === 'certificados' && (
                    <React.Fragment>
                        {
                            universityContent.length > 0 ? (
                                <React.Fragment>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'flex-end',
                                            marginBottom: '8px',
                                            marginTop: '8px',
                                            flexFlow: 'column'
                                        }}>
                                        <span>{getTotalMateriais()}</span>
                                    </div>

                                    <ContentWrapper>
                                        <ContentGrid>
                                            {universityContent.map((item: UniversityEntity) => (
                                                <StyledCard key={item.id}>
                                                    <StyledCardMedia
                                                        sx={{height: 140}}
                                                        image={item.fileUrlImage}
                                                        defaultValue='lib/assets/images/logo-ms.png' // Chama quando a imagem não carrega
                                                        title={item.name}
                                                    />
                                                    <CardContent>
                                                        <Typography variant="h6" component="div">
                                                            {item.name}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" sx={{mt: 1}}>
                                                            {item.resume}
                                                        </Typography>
                                                        <Typography variant="body1" sx={{mt: 3}}>Finalizado
                                                            em: {item.date_of_finished}</Typography>
                                                    </CardContent>
                                                    <CardActions>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'flex-start',
                                                                marginBottom: '4px',
                                                                marginTop: '8px',
                                                                flexFlow: 'column',
                                                                gap: '12px'
                                                            }}>
                                                            <StyledButton variant="contained" color="primary"
                                                                          onClick={() => downloadCertificate(item.id, item.name)}>
                                                                <DownloadIcon/>
                                                                Baixar certificado
                                                            </StyledButton>
                                                        </div>
                                                    </CardActions>
                                                </StyledCard>
                                            ))}
                                        </ContentGrid>
                                    </ContentWrapper>
                                </React.Fragment>
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        marginBottom: '8px',
                                        marginTop: '8px',
                                        flexFlow: 'column'
                                    }}>
                                    <Typography variant="body1">{getMessageByTypeOfContent()}</Typography>
                                </div>
                            )
                        }
                    </React.Fragment>
                )}

            </BaseCardBox>

            {snackBarSettings.show && (
                <CustomizedSnackbars
                    message={snackBarSettings.message}
                    severity={snackBarSettings.severity}
                />
            )}
        </Container>
    );
}
